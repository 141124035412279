import React, { useEffect, useContext, useState } from "react";
import Auth from "../util/Auth";
import Navbar from "./Navbar";
import "./Jobs.css"
import { AuthContext } from "../util/AuthContext";
import Accordion from "./Accordion";

const Expenses = () => {
    const context = useContext(AuthContext);

    const [data, setData] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    useEffect(() => {
        setData(context.expenses);
    }, [context.expenses])
    

    const handleSearchQuery = (event) => {
        if(event.target.value === "") {
            setSearchResult("");
        } else {
            setSearchQuery(event.target.value);
            handleSearch();
        }
    }

    const handleSearch = () => {
        const itemsFiltered = data.filter(item =>
            item.firstDay.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.lastDay.toLowerCase().includes(searchQuery.toLowerCase())
          );
        setSearchResult(itemsFiltered)
    }
        return (
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <Navbar/>
                    {data !== null ?
                    <div className="col py-3">
                        <div className="row">
                            <div className="col-7 col-lg-5">
                                <div className="bg-light rounded rounded-pill shadow-sm mb-4">
                                    <div className="input-group">
                                        <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon1" className="form-control search border-0 bg-light" onChange={handleSearchQuery}/>
                                        <div className="input-group-append">
                                            <button id="button-addon1" type="submit" className="btn btn-link text-primary"><i className="bi bi-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            {searchResult.length === 0 ?
                                <Accordion data={data}/>
                             :
                                <Accordion data={searchResult}></Accordion>
                             }
                        </div>
                    </div>
                    : "" }
                </div>
            </div>
        )
}

export default Auth(Expenses);