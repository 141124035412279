import React, { useState } from "react";
import Pagination from "./Pagination";
import "./Table.css"
import { Link } from "react-router-dom";

const WarrantiesTable = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 10;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = props.warranties.slice(startIndex, endIndex);
    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setStartIndex((pageNumber - 1) * itemsPerPage)
    }

    const handleEdit = (data) => {
        props.edit(data);
    }

    const handleDelete = (data) => {
        props.delete(data);
    }


    return (
    <div>
        <div className="table-responsive-lg container pb-1">
        <table className="table table-striped table-hover table-fixed">
            <thead>
                <tr>
                <th scope="col">Car</th>
                <th scope="col">Part</th>
                <th scope="col">Part Number</th>
                <th scope="col">Store</th>
                <th scope="col">Warranty Time</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {displayedItems.map((warranty) => (
                    <tr key={warranty.id}>
                        <td>{warranty.car}</td>
                        <td>{warranty.part}</td>
                        <td>{warranty.partNumber}</td>
                        <td>{warranty.store}</td>
                        <td>{warranty.warrantyTime}</td>
                        <td>{warranty.date}</td>
                        <td>
                            <Link onClick={() => handleEdit(warranty)} className="bi bi-pencil text-warning pe-2"></Link>
                            <Link onClick={() => handleDelete(warranty)} className="bi bi-trash text-danger"></Link>
                        </td>
                    </tr>
                )
                )}
            </tbody>
        </table>
        <Pagination
        totalPages={Math.ceil(props.warranties.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={onPageChange}
        />
        </div>
    </div>
    )
}

export default WarrantiesTable;