import React from "react";
import ErrorPage from './ErrorPage';
import axios from "axios";
import { uri } from "../util/Rest";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: undefined
        }
    }

    static getDerivedStateFromError(error) {
        // specify that the error boundary has caught an error
        return {
            hasError: true,
            error: error
        };
    }

    componentDidCatch(error, errorInfo) {

        axios.post(`${uri}/error`, error);
    }

    render() {
        // if an error occurred
        if (this.state.hasError) {
            return <ErrorPage />;
        } else {
            // default behavior
            return this.props.children;
        }
    }
}

export default ErrorBoundary;