import React, {useState} from "react";

const Modal = (props) => {
    const [showModal, setShowModal] = useState(true);
  
    const closeModal = () => {
      setShowModal(false);
      props.cancel();
    };
  
    return (
      <div>
        {showModal && (
          <div className="modal" style={{display: "block", "background-color": "rgba(0, 0, 0, 0.5)"}} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Are you sure?</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="d-flex justify-content-center">
                        <i className="fs-1 text-danger bi bi-exclamation-circle-fill"/> 
                    </div>
                    <p className="text-center text-secondary">You are about to delete this entry.  <br/> This action cannot be undone.</p>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>No, cancel</button>
                    <button type="button" className="btn btn-danger" onClick={props.delete}>Yes, delete</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}


export default Modal;