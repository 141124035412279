import React, {useState, memo} from "react";
import { Link } from "react-router-dom";

const Pagination = ({totalPages, currentPage, onPageChange}) => {
    const [startIndex, setStartIndex] = useState(1);
    const handlePageChange = (pageNumber) => {
      onPageChange(pageNumber);
  
      // Ensure that the current page is in the center of the displayed page links
      if (pageNumber >= startIndex + 5) {
        setStartIndex(pageNumber - 2);
      } else if (pageNumber <= startIndex && startIndex > 1) {
        if(pageNumber === 1) {
            setStartIndex(1);
        } else {
            setStartIndex(pageNumber - 3);
        }
      }
    };
  
    const renderPages = () => {
      const pages = [];
      const endIndex = Math.min(startIndex + 4, totalPages);
  
      // Always display the first page link
      pages.push(
        <li
          key={1}
          className={`page-item ${1 === currentPage ? "active" : ""}`}
          onClick={() => handlePageChange(1)}
        >
          <Link className="page-link">1</Link>
        </li>
      );
  
      // Add separator between first page link and second page link if there is a gap
      if (startIndex > 2) {
        pages.push(
          <li key="separator1" className="page-item disabled">
            <Link className="page-link">...</Link>
          </li>
        );
      }
  
      // Generate the range of page links centered around the current page
      for (let i = startIndex; i <= endIndex; i++) {
        // Skip the first and last pages since they're always displayed
        if (i !== 1 && i !== totalPages) {
          // Only add a page link if it's a positive integer
          if (i > 0) {
            pages.push(
              <li
                key={i}
                className={`page-item ${
                  i === currentPage ? "active" : ""
                }`}
                onClick={() => handlePageChange(i)}
              >
                <Link className="page-link">{i}</Link>
              </li>
            );
          }
        }
      }
  
      // Add separator between second-to-last page link and last page link if there is a gap
      if (endIndex < totalPages - 1) {
        pages.push(
          <li key="separator2" className="page-item disabled">
            <Link className="page-link">...</Link>
          </li>
        );
      }
  
      // Always display the last page link
      if (totalPages > 1) {
        pages.push(
          <li
            key={totalPages}
            className={`page-item ${
              totalPages === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(totalPages)}
          >
            <Link className="page-link">{totalPages}</Link>
          </li>
        );
      }
  
      return pages;
    };
  
    return (
      <nav>
        <ul className="pagination justify-content-center">
          <li
            className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <Link className={`page-link ${currentPage === 1 ? "d-none" : ""}`} aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </Link>
          </li>
          {renderPages()}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <Link className={`page-link ${currentPage === totalPages ? "d-none" : ""}`} aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
}

export default memo(Pagination);