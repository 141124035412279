import React, { useState, memo } from "react";
import Pagination from "./Pagination";
import "./Table.css"
import { Link } from "react-router-dom";

const Table = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 10;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = props.jobs.slice(startIndex, endIndex);
    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setStartIndex((pageNumber - 1) * itemsPerPage)
    }

    const handleEdit = (data) => {
        props.edit(data);
    }

    const handleDelete = (data) => {
        props.delete(data);
    }


    return (
        <div className="table-responsive-lg container">
        <table className="table table-striped table-hover py-5">
            <thead>
                <tr>
                <th scope="col">Name</th>
                <th scope="col">Car</th>
                <th scope="col">Job</th>
                <th scope="col">Price</th>
                <th scope="col">Expenses</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {displayedItems.map((job) => (
                    <tr key={job.id}>
                        <td>{job.name}</td>
                        <td>{job.car}</td>
                        <td>{job.job}</td>
                        <td>{job.price}</td>
                        <td>{job.expenses}</td>
                        <td>{job.date}</td>
                        <td>
                            <Link onClick={() => handleEdit(job)} className="bi bi-pencil text-warning pe-2"></Link>
                            <Link onClick={() => handleDelete(job)} className="bi bi-trash text-danger"></Link>
                        </td>
                    </tr>
                )
                )}
            </tbody>
        </table>
        <Pagination
        totalPages={Math.ceil(props.jobs.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={onPageChange}
        />
        </div>
    )
}

export default memo(Table);