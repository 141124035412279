import React, { useCallback, useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { uri } from "../util/Rest";
import { Link } from "react-router-dom";
import AlertComponent from "./AlertComponent";
import Modal from "./Modal";
import Auth from "../util/Auth";
import AddUser from "./AddUser";
import EditUser from "./EditUser";

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const [addBtn, setAddBtn] = useState(false);
    const [editBtn, setEditBtn] = useState(false);
    const [deleteBtn, setDeleteBtn] = useState(false);

    const [editData, setEditData] = useState(null);
    const [deleteData, setDeleteData] = useState(null);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    const getUsers = useCallback(() => {
        axios.get(`${uri}/users`)
        .then((response) => {
            setUsers(response.data);
            setSearchResult(response.data)
        })
    }, [])

    useEffect(() => {
        getUsers();
    }, [getUsers])


    const handleSearchQuery = (event) => {
        if(event.target.value === "") {
            setSearchResult(users);
        } else {
            setSearchQuery(event.target.value);
            handleSearch();
        }
    }

    const handleSearch = () => {
        const itemsFiltered = users.filter(item =>
            item.username.toLowerCase().includes(searchQuery.toLowerCase())
          );
        setSearchResult(itemsFiltered)
    }

    const handleDeleteBtn = (data) => {
        setDeleteBtn(true);
        setDeleteData(data);
    }

    const handleDelete = async () => {
        await axios.delete(`${uri}/user`, {data: deleteData})
        .then(() => {
            handleSubmit("User Deleted");
        })
    }

    const handleCancel = () => {
        setAddBtn(false);
        setEditBtn(false);
    }

    const handleAddBtn = () => {
        setAddBtn(true);
    }

    const handleSubmit = (msg) => {
        setEditBtn(false);
        setAddBtn(false);
        setDeleteBtn(false);
        setShowAlert(true);
        setAlertMsg(msg);
        getUsers();
    }

    const handleEdit = (data) => {
        setEditData(data)
        setEditBtn(true);

    }
    return (
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <Navbar/>
                {users !== null ? 
                        addBtn === false ?
                            editBtn === false ?
                <div className="col py-3">
                <div className="row">
                    <div className="col-7 col-lg-5">
                        <div className="bg-light rounded rounded-pill shadow-sm mb-4">
                            <div className="input-group">
                                <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon1" className="form-control search border-0 bg-light" onChange={handleSearchQuery}/>
                                <div className="input-group-append">
                                    <button id="button-addon1" type="submit" className="btn btn-link text-primary"><i className="bi bi-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-lg-2">
                        <button className="btn w-100 btn-primary" onClick={handleAddBtn}>Create User</button>
                    </div>
                    {showAlert === true ?
                    <div className="col-10 col-lg-5">
                        <AlertComponent message={alertMsg} dismissTime={5000}/>
                    </div>
                    : "" }
                </div>
                    <div className="table-responsive-lg container pb-1">
                        <table className="table table-striped table-hover table-fixed">
                        <thead>
                            <tr>
                            <th scope="col">Username</th>
                            <th scope="col">User Role</th>
                            <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {searchResult.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.username}</td>
                                    <td>{user.userRole}</td>
                                    <td>
                                        <Link onClick={() => handleEdit(user)} className="bi bi-pencil text-warning pe-2"></Link>
                                        <Link onClick={() => handleDeleteBtn(user)} className="bi bi-trash text-danger"></Link>
                                    </td>
                                </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <EditUser cancel={handleCancel} submit={() => handleSubmit("User Edited")} data={editData}/>
                :
                <AddUser cancel={handleCancel} submit={() => handleSubmit("User Created")}/>
                : "" 
                }
            </div>
            {deleteBtn === true && <Modal cancel={handleCancel} delete={handleDelete}/>}
        </div>
    )
}

export default Auth(ManageUsers);