import React, { useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import jwtdecode from 'jwt-decode'
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { uri } from './Rest';

const Auth = (Component) => {
    const ProtectedComponent = (props) => {
        const context = useContext(AuthContext);
        const navigate = useNavigate();
        useEffect(() => {
            axios.get(`${uri}/verifyToken`)
            .then(async (response) => {
                const authenticated = response.data.jwt;
                if(authenticated === undefined) {
                    return navigate("/");
                } else {
                    const decoded = jwtdecode(authenticated);
                    if(decoded.exp < (Date.now() / 1000)) {
                        return navigate("/")
                    }
                    await axios.get(`${uri}/userId`).then(async (response) => {
                        if(context.user === null) {
    
                            context.handleUserData(response.data)
                        }
                    })
                }
            })
            .catch((error) => {
                if(error) {
                    console.log(error);
                    return navigate("/");
                }
            })
        }, [])
        return <Component {...props}/>
    }
    return ProtectedComponent;
}

export default Auth;