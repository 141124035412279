import React, { useState } from "react";
import Pagination from "./Pagination";

const Accordion = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 10;
    const endIndex = startIndex + itemsPerPage;
    const displayedData = props.data.slice(0).reverse().slice(startIndex, endIndex);
    console.log(displayedData)
    if(displayedData[displayedData.length - 1]) {
        if(displayedData[displayedData.length - 1].id === undefined)
        displayedData.pop();
    }


    const [activePanel, setActivePanel] = useState('');
  
    const togglePanel = (panelId) => {
      setActivePanel((prevPanel) => (prevPanel === panelId ? '' : panelId));
    };

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setStartIndex((pageNumber - 1) * itemsPerPage)
    }
  
    return (
         <div className="container pb-1">
            <div className="accordion accordion-flush">
                {displayedData.map((d) => (
                    <div key={d.id} className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                            className={`accordion-button ${d.income >= 0 ? 'bg-success' : 'bg-danger'} ${activePanel === `panel${d.id}` ? 'active' : 'collapsed'}`}
                            onClick={() => togglePanel(`panel${d.id}`)}
                            >
                                <div className="text-white px-2 d-flex w-100 justify-content-between">
                                    <div>
                                        <p>{d.firstDay} - {d.lastDay}</p>
                                    </div>
                                    <div className="px-3">
                                        Income: ${d.income}
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div className={`accordion-collapse px-3 accordion-header collapse ${activePanel === `panel${d.id}` ? 'show' : ''}`}>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Job</th>
                                        <th>Income</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {d.jobs.map((job) => (
                                    <tr key={job.id} className={job.price - job.expenses > 0 ? "" : "table-danger" }>
                                        <td>
                                            {job.date}
                                        </td>
                                        <td>
                                            {job.name}
                                        </td>
                                        <td>
                                            {job.job}
                                        </td>
                                        <td>
                                            ${(job.price - job.expenses).toFixed(2)}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
            <Pagination
                totalPages={Math.ceil(props.data.length / itemsPerPage)}
                currentPage={currentPage}
                onPageChange={onPageChange}
            />
      </div>
    );
  };

export default Accordion;