import React, { useEffect, useContext, useState } from "react";
import Auth from "../util/Auth";
import Navbar from "./Navbar";
import axios from "axios";
import WarrantiesTable from "./WarrantiesTable";
import { uri } from "../util/Rest";
import AddWarranty from "./AddWarranty";
import "./Jobs.css"
import EditWarranty from "./EditWarranty";
import AlertComponent from "./AlertComponent";
import Modal from "./Modal";
import { AuthContext } from "../util/AuthContext";
const Warranties = () => {
    const context = useContext(AuthContext);

    const [data, setData] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [noneFound, setNoneFound] = useState(false);

    const [addBtn, setAddBtn] = useState(false);
    const [editBtn, setEditBtn] = useState(false);
    const [deleteBtn, setDeleteBtn] = useState(false);

    const [editData, setEditData] = useState(null);
    const [deleteData, setDeleteData] = useState(null);


    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMsg, setShowAlertMsg] = useState("");

    useEffect(() => {
        setData(context.warranties);
    }, [context.warranties])
    

    const handleSearchQuery = (event) => {
        if(event.target.value === "") {
            setSearchResult("");
            setNoneFound(false);
        } else {
            setSearchQuery(event.target.value);
            handleSearch();
        }
    }

    const handleSearch = () => {
        const itemsFiltered = data.filter(item =>
            item.car.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.part.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.partNumber.toLowerCase().includes(searchQuery.toLowerCase())
          );
        setSearchResult(itemsFiltered)
        if(searchResult.length === 0) {
            setNoneFound(true);
        }
    }

    const handleAddJobBtn = () => {
        setAddBtn(true);
    }

    const handleAddCancel = () => {
        setAddBtn(false);
    }

    const handleAddSubmit = () => {
        setAddBtn(false);
        context.handleWarrantiesData();
        setShowAlert(true);
        setShowAlertMsg("Warranty entry created");
    }

    const handleEditSubmit = () => {
        setEditBtn(false);
        context.handleWarrantiesData();
        setShowAlert(true);
        setShowAlertMsg("Changes saved");
        
    }

    const handleEditCancel = () => {
        setEditBtn(false);
    }

    const handleEditBtn = (data) => {
        setEditBtn(true);
        setEditData(data);
    }

    const handleDeleteBtn = (data) => {
        setDeleteBtn(true);
        setDeleteData(data);
    }

    const handleDeleteCancel = () => {
        setDeleteBtn(false);
    }

    const handleDeleteSubmit = async () => {
        setDeleteBtn(false);
        await axios.delete(`${uri}/warranties`, {data: deleteData})
        .then(() => {
            context.handleWarrantiesData();
        })
        .catch((err) => {
            console.log(err);
        });
        setShowAlert(true);
        setShowAlertMsg("Warranty entry deleted")
    }
        return (
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <Navbar/>
                    {data !== null ?
                    addBtn === false ?
                        editBtn === false ?
                    <div className="col py-3">
                        <div className="row">
                            <div className="col-7 col-lg-5">
                                <div className="bg-light rounded rounded-pill shadow-sm mb-4">
                                    <div className="input-group">
                                        <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon1" className="form-control search border-0 bg-light" onChange={handleSearchQuery}/>
                                        <div className="input-group-append">
                                            <button id="button-addon1" type="submit" className="btn btn-link text-primary"><i className="bi bi-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 col-lg-2">
                                <button className="btn w-50 btn-success" onClick={handleAddJobBtn}>Add</button>
                            </div>
                            {showAlert === true ?
                            <div className="col-10 col-lg-5">
                                <AlertComponent message={showAlertMsg} dismissTime={5000}/>
                            </div>
                            : "" }
                        </div>
                        <div className="container-fluid">
                            {searchResult.length === 0 ?
                                noneFound === false ?
                                
                                <WarrantiesTable edit={handleEditBtn} delete={handleDeleteBtn} warranties={data}/>
                                :
                                <h1>None Found</h1>
                             :
                                <WarrantiesTable edit={handleEditBtn} delete={handleDeleteBtn} warranties={searchResult}/>
                             }
                        </div>
                    </div>
                    :
                    <EditWarranty data={editData} cancel={handleEditCancel} submit={handleEditSubmit}/>
                    :
                    <AddWarranty cancel={handleAddCancel} submit={handleAddSubmit}/>
                    : ""
                    }
                </div>
                {deleteBtn === true && <Modal cancel={handleDeleteCancel} delete={handleDeleteSubmit}/>}
            </div>
        )
}

export default Auth(Warranties);