import React from 'react';
import './ErrorPage.css';

const ErrorPage = () => {
  return (
    <div className="error-page">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="error-page-content">
            <h1 className="error-page-heading">404</h1>
            <h3 className="error-page-subheading">Oops! Page Not Found</h3>
            <p className="error-page-text">
              The page you are looking for might have been removed, had its name changed,
              or is temporarily unavailable.
            </p>
            <a href="/" className="btn btn-primary error-page-btn">
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="error-page-background"></div>
  </div>
  );
};

export default ErrorPage;