import axios from "axios";
import React, { useState } from "react";
import { uri } from "../util/Rest";

const EditWarranty = ({cancel, submit}) => {
    const [formState, setFormState] = useState({id: -1, car: "", part: "", partNumber: "", store: "", warrantyTime: "" , price: "", date: null});

    const handleSubmit = async (event) => {
        event.preventDefault();
        await axios.post(`${uri}/warranties`, formState)
        .then(() => {
            submit();
        });
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value})
    }

    return (
        <div className="col py-3">
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="car" className="form-label">Car</label>
                <div className="input-group">
                <input type="text" className="form-control" name="car" id="car" value={formState.car} onChange={handleChange} placeholder="Enter car" required/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="part" className="form-label">Part</label>
                <div className="input-group">
                <input type="text" className="form-control" name="part" id="part" value={formState.part} onChange={handleChange} placeholder="Enter part name" required/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="partNumber" className="form-label">Part Number</label>
                <div className="input-group">
                <input type="text" className="form-control" name="partNumber" id="partNumber" value={formState.partNumber} onChange={handleChange} placeholder="Enter part name" required/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="store" className="form-label">Store</label>
                <div className="input-group">
                <input type="text" className="form-control" name="store" id="store" value={formState.store} onChange={handleChange} placeholder="Enter store name" required/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="warrantyTime" className="form-label">Warranty Time</label>
                <div className="input-group">
                <input type="text" className="form-control" name="warrantyTime" id="warrantyTime" value={formState.warrantyTime} onChange={handleChange} placeholder="Enter warranty time" required/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="date" className="form-label">Date</label>
                <div className="input-group">
                <input type="date" className="form-control" name="date" id="date" value={formState.date} onChange={handleChange} required/>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <button type="submit" className="btn btn-outline-primary btn w-25 mb-3">Create</button>
                <button type="submit" onClick={cancel} className="btn btn-outline-secondary btn w-25 mb-3">Cancel</button>
            </div>
            </form>
        </div>
    )
}

export default EditWarranty;