import React, {useContext, useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Collapse } from 'bootstrap';
import "./Navbar.css"
import { AuthContext } from '../util/AuthContext';
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAdminOpen, setIsAdminOpen] = useState(false)

    const  { user }  = useContext(AuthContext);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }

    const toggleAdminMenu = () => {
        setIsAdminOpen(!isAdminOpen)
        var collapseId = document.getElementById("collapseTarget");
        console.log(collapseId)
        var bsCollapse = new Collapse(collapseId, {isAdminOpen: false})
        isAdminOpen ? bsCollapse.show() : bsCollapse.hide()
    }
    const menuClass = `dropdown-menu dropdown-menu-dark text-small shadow ${isOpen ? " show" : ""}`; 
    if(user !== null) {
        return (
            <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 sidebar">
                <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <Link to="/dashboard" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">Portal</span>
                </Link>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 w-100 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item w-100">
                        <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'nav-link align-middle px-0 activeLink' :"nav-link align-middle px-0")}>
                            <i className="fs-4 bi-house" /> <span className="ms-1 d-none d-sm-inline">Home</span>
                        </NavLink>
                    </li>
                    <li className='nav-item w-100'>
                        <NavLink to="/jobs" className={({ isActive }) => (isActive ? 'nav-link align-middle px-0 activeLink' :"nav-link align-middle px-0")}>
                            <i className="fs-4 bi-briefcase" /> <span className="ms-1 d-none d-sm-inline">Jobs</span>
                        </NavLink>
                    </li>
                    <li className='nav-item w-100'>
                        <NavLink to="/warranties" className={({ isActive }) => (isActive ? 'nav-link align-middle px-0 activeLink' :"nav-link align-middle px-0")}>
                            <i className="fs-4 bi-shield-check" /> <span className="ms-1 d-none d-sm-inline">Warranties</span>
                        </NavLink>
                    </li>
                    <li className='nav-item w-100'>
                        <NavLink to="/expenses" className={({ isActive }) => (isActive ? 'nav-link align-middle px-0 activeLink' :"nav-link align-middle px-0")}>
                            <i className="fs-4 bi-wallet" /> <span className="ms-1 d-none d-sm-inline">Expenses</span>
                        </NavLink>
                    </li>
                    {user.userRole >= 5 ? 
                    <div className='mt-5'>
                        <li>
                            <Link onClick={toggleAdminMenu} className="nav-link px-0 align-middle">
                                <i className="fs-4 bi-tools" /> <span className="ms-1 d-none d-sm-inline">Admin</span> </Link>
                            <ul className="collapse nav flex-column ms-1" id="collapseTarget">
                                <li className="w-100">
                                    <NavLink to="/admin/users" className={({ isActive }) => (isActive ? 'nav-link align-middle px-0 activeLink' :"nav-link align-middle px-0")}> <i className='fs-4 bi-people'/> <span className="d-none d-sm-inline">Manage Users</span></NavLink>
                                </li>
                            </ul>
                        </li>
                    </div> 
                    : 
                    <div>

                    </div>
                    
                    }
                </ul>
                <hr />
                <div className="dropup dropup-center pb-4" onClick={toggleOpen}>
                    <div className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="d-none d-sm-inline mx-1">{user.username}</span>
                    </div>
                    <ul className={menuClass}>
                    <li><Link className="dropdown-item" to="/settings">Settings</Link></li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li><NavLink to="/logout" className="dropdown-item" href="#">Sign out</NavLink></li>
                    </ul>
                </div>
                </div>
            </div>
        );
    }
}

export default Navbar;
