import React, { useState, useEffect } from 'react';

const AlertComponent = ({ message, dismissTime }) => {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, dismissTime);

    return () => {
      clearTimeout(timer);
    };
  }, [dismissTime]);

  return (
    <div className='d-flex justify-content-end'>
      {showAlert && (
        <div className="alert alert-success alert-dismissible fade show" role="alert" style={{fontSize: "0.875em" ,border: "1px solid green"}}>
            <i className="bi-check-circle-fill"></i>
            <strong className='mx-2'>Success!</strong>{message}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
          </button>
        </div>
      )}
    </div>
  );
};

export default AlertComponent;