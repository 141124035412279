import React, { useContext, useEffect } from "react";
import { AuthContext } from "../util/AuthContext";
import { Navigate } from "react-router-dom";

const Logout = () => {
    const {handleLogout} = useContext(AuthContext);
    
    useEffect(() => {
        handleLogout();
    }, [handleLogout])
    return (
        <div>
            <Navigate to="/"/>
        </div>
    )
}

export default Logout;