import React, { useContext, useEffect, useState } from "react";
import Auth from "../util/Auth";
import Navbar from "./Navbar";
import { AuthContext } from "../util/AuthContext";
import LineChart from "./LineChart";
import "./Dashboard.css";

const Dashboard = () => {
    console.log("Dashboard")
    const context = useContext(AuthContext);
    const [jobsPerMonth, setJobsPerMonth] = useState([]);
    const [warrantiesPerMonth, setWarrantiesPerMonth] = useState([]);
    const [expenses, setExpenses] = useState(0)
    useEffect(() => {
        setJobsPerMonth(perMonthData(context.jobs));
        setWarrantiesPerMonth(perMonthData(context.warranties))
        setExpenses(context.expenses)
    }, [context.jobs, context.warranties, context.expenses])
    const perMonthData = (data) => {
        let arr = [
            {"labels": "January", "values": 0},
            {"labels": "February", "values": 0},
            {"labels": "March", "values": 0},
            {"labels": "April", "values": 0},
            {"labels": "May", "values": 0},
            {"labels": "June", "values": 0},
            {"labels": "July", "values": 0},
            {"labels": "August", "values": 0},
            {"labels": "September", "values": 0},
            {"labels": "October", "values": 0},
            {"labels": "November", "values": 0},
            {"labels": "December", "values": 0}
        ];
      for(const d of data) {
        let year = new Date(d.date).getFullYear();
        let todayDate = new Date();
        if(year === todayDate.getFullYear()) {
            let date = new Date(d.date).toLocaleString('default', { month: 'long' });
            for(const a of arr) {
                if(a.labels === date) {
                    a.values++;
                }
            }
        }

        if(year === todayDate.getFullYear() - 1 && todayDate.getMonth() <= 2) {
            let date = new Date(d.date).toLocaleString('default', { month: 'long' });
                if(date === "December") {
                    arr[11].values++;
                } else if(date === "November") {
                    arr[10].values++;
                }
        }
      }
      return arr;
    }  
    const perYearIncome = (data) => {
        const currentYear = new Date().getFullYear();
        let income = 0.00;
        for(const d of data) {
            let date = new Date(d.firstDay).getFullYear();
            if(date === currentYear) {
                income += parseFloat(d.income);
            }
        }
        return income.toFixed(2);
    }

    const lastMonthData = (data) => {
        const currentMonth = new Date().getMonth();
        let lastMonth = currentMonth - 1;
        if(lastMonth <= 1) {
            lastMonth = 11;
        }
        const lastMonthData = data[lastMonth].values;
        const beforeLastMonthData = data[lastMonth - 1].values
        const percentage = ((lastMonthData - beforeLastMonthData) / beforeLastMonthData * 100).toFixed(2)
        return {lastMonthData, percentage };
    }



    return (
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <Navbar/>
                {context.user === null || !jobsPerMonth.length || !warrantiesPerMonth.length ? "" :
                <div className="col py-3">
                    <div className="container">
                    <h1>Welcome, {context.user.username}!</h1>
                        <div className="row d-flex justify-content-start justify-content-sm-around">
                            <div className="col-12 col-lg-5 mb-5">
                                <div className="card mx-auto">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Jobs This Year</h5>
                                        <LineChart label="Total Jobs" data={jobsPerMonth}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="card mx-auto">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Warranties This Year</h5>
                                        <LineChart label="Total Warranties" data={warrantiesPerMonth}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-11 mt-5">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Income This Year</h5>
                                        <h4 className="card-body">${perYearIncome(expenses)}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 mt-5">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <div className="row d-flex justify-content-around">
                                            <div className="col-9">
                                                <h5 className="card-title">Jobs Last Month</h5>
                                            </div>
                                            <div className="col-3">
                                                <p className={lastMonthData(jobsPerMonth).percentage > 0 ? "text-success" : "text-danger"}> {lastMonthData(jobsPerMonth).percentage}% <i className={lastMonthData(jobsPerMonth).percentage > 0 ? "bi bi-graph-up text-success" : "bi bi-graph-down text-danger" }/> </p>
                                            </div>
                                        </div>
                                        <h4 className="card-body">{lastMonthData(jobsPerMonth).lastMonthData}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 mt-5">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <div className="row d-flex justify-content-around">
                                            <div className="col-9">
                                                <h5 className="card-title">Warranties Last Month</h5>
                                            </div>
                                            <div className="col-3">
                                                <p className={lastMonthData(warrantiesPerMonth).percentage > 0 ? "text-success" : "text-danger"}> {lastMonthData(warrantiesPerMonth).percentage}% <i className={lastMonthData(warrantiesPerMonth).percentage > 0 ? "bi bi-graph-up text-success" : "bi bi-graph-down text-danger" }/> </p>
                                            </div>
                                        </div>
                                        <h4 className="card-body">{lastMonthData(warrantiesPerMonth).lastMonthData}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Auth(Dashboard);