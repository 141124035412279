import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AuthContextProvider from './components/AuthContextProvider';
import ErrorBoundary from './components/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <ErrorBoundary>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </ErrorBoundary>
);

