import React, {useContext, useState} from "react"
import "./Login.css"

import axios from 'axios'

import { AuthContext } from "../util/AuthContext"
import {useNavigate} from 'react-router-dom'
import {uri} from '../util/Rest'

const Login = () => {
    console.log("Login");
    const [formState, setFormState] = useState({ username: '', password: '' });
    const [loginError, setLoginError] = useState("");
    const context = useContext(AuthContext);

    const navigate = useNavigate();

    const populateUser = async () => {
        await axios.get(`${uri}/userId`)
            .then((response) => {
                if(context.user === null) {
                    context.handleUserData(response.data)
                }
            })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Submit")
        await axios.post(`${uri}/login`, formState)
        .then(async () => {
            await populateUser();
            await context.handleJobsData();
            await context.handleWarrantiesData();
            await context.handleExpensesData();
            navigate("/dashboard");

        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // Request made and server responded with a non-2xx status code
                if (error.response.status === 401) {
                  // display login error message in the form
                    setLoginError("An error has occurred, please let the developer know");
                } else if(error.response.status === 404) {
                    setLoginError("Invalid Username or Password")
                }
              } else {
                // Something happened in setting up the request that triggered an Error
                setLoginError(`An error has occurred, please let the developer know`);
              }
        });

        
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value})
    }
    return (
        <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center min-vh-100">
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                    <div className="card mx-auto shadow-lg border-0">
                        <div className="card-body p-5">
                            <h5 className="card-title text-center mb-4">Login</h5>
                            <form onSubmit={handleSubmit}>
                                {loginError && <p className="errorMessage">
                                <i className="bi bi-exclamation-triangle"></i> {loginError}</p> }
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input type="text" className="form-control" name="username" id="username" value={formState.username} placeholder="Enter username" onChange={handleChange} required/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <div className="input-group">
                                <input type="password" className="form-control" name="password" id="password" value={formState.password} placeholder="Enter password" onChange={handleChange} required/>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-success btn-lg w-100 mb-3">Login</button>
                            </form>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;