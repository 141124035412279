import { useCallback, useEffect, useState } from "react";
import { AuthContext } from "../util/AuthContext";
import axios from "axios";
import { uri } from "../util/Rest";

const AuthContextProvider = (props) => {
    const [user, setUser] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [warranties, setWarranties] = useState([]);
    const [expenses, setExpenses] = useState([]);

    const populateData = useCallback(() => {
        axios.get(`${uri}/verify`)
        .then((response) => {
            console.log("H")
            if(response.data === true) {
                handleWarrantiesData();
                handleJobsData();
                handleExpensesData();
            }
        })
        
    }, [])

    useEffect(() => {
        populateData();
    }, [populateData])


    const handleUserData = (userData) => {
        setUser(userData);
    }

    const handleJobsData = async () => {
        await axios.get(`${uri}/jobs`)
        .then((response) => {
            setJobs(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleWarrantiesData = async () => {
        await axios.get(`${uri}/warranties`)
        .then((response) => {
            setWarranties(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
    }
    const handleExpensesData = async () => {
        await axios.get(`${uri}/expenses`)
        .then((response) => {
            setExpenses(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleLogout = async () => {
        await axios.post(`${uri}/logout`).then(() => {
            setUser(null);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const contextValue = {
        user,
        jobs,
        warranties,
        expenses,
        handleUserData,
        handleJobsData,
        handleExpensesData,
        handleWarrantiesData,
        handleLogout
    };
  
    return (
            <AuthContext.Provider value={contextValue}>
                {props.children}
            </AuthContext.Provider>
    )
}

export default AuthContextProvider;
