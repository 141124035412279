import axios from "axios";
import React, {useState } from "react";
import { uri } from "../util/Rest";

const AddJob = ({cancel, submit}) => {
    const [formState, setFormState] = useState({name: "", car: "", job: "", price: "", expenses: "", date: "", files: ""});
    const handleSubmit = async (event) => {
        event.preventDefault();
        await axios.post(`${uri}/jobs`, formState)
        .then(() => {
            submit();
        });
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value})
    }

    return (
        <div className="col py-3">
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="customer" className="form-label">Customer Name</label>
                <input type="text" className="form-control" name="name" id="customer" value={formState.name} onChange={handleChange} placeholder="Enter customer's name" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="car" className="form-label">Car</label>
                <div className="input-group">
                <input type="text" className="form-control" name="car" id="car" value={formState.car} onChange={handleChange} placeholder="Enter car" required/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="job" className="form-label">Job</label>
                <div className="input-group">
                <input type="text" className="form-control" name="job" id="job" value={formState.job} onChange={handleChange} placeholder="Enter job description" required/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="price" className="form-label">Price</label>
                <div className="input-group">
                <span class="input-group-text" id="inputGroupPrepend">$</span>
                <input type="text" className="form-control" name="price" id="price" value={formState.price} onChange={handleChange} placeholder="Enter Price" required/>
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="expenses" className="form-label">Expenses</label>
                <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend">$</span>
                <input type="text" className="form-control" name="expenses" id="expenses" value={formState.expenses} onChange={handleChange} placeholder="Total Expenses ('0' if none)" required/>
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="date" className="form-label">Date</label>
                <div className="input-group">
                <input type="date" className="form-control" name="date" id="date" value={formState.date} onChange={handleChange} required/>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <button type="submit" className="btn btn-success btn w-25 mb-3">Create</button>
                <button type="submit" onClick={cancel} className="btn btn-secondary btn w-25 mb-3">Cancel</button>
            </div>
            </form>
        </div>
    )
}

export default AddJob;