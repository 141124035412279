import Login from "./components/Login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Jobs from "./components/Jobs";
import Warranties from "./components/Warranties";
import Expenses from "./components/Expenses";
import ManageUsers from "./components/ManageUsers";
import { useEffect, useState } from "react";
import axios from "axios";
import { uri } from "./util/Rest";
import Logout from "./components/Logout";
import Settings from "./components/Settings";

const App = () => {
    axios.defaults.withCredentials = true;
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        axios.get(`${uri}/verify`)
        .then((response) => {
            setIsLoggedIn(response.data);
        })
        .catch((error) => {
            console.log(`Error: ${error}`);
        })
    }, [])
    console.log(isLoggedIn);
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={isLoggedIn === false ? <Login/> : <Navigate to="/dashboard" replace/> } />
                <Route exact path="/dashboard" Component={Dashboard} />
                <Route path ='/jobs' Component={Jobs} />
                <Route exact path ='/warranties' Component={Warranties} />
                <Route exact path ='/expenses' Component={Expenses} />
                <Route exact path="/admin/users" Component={ManageUsers}/>
                <Route exact path="/settings" Component={Settings}/>
                <Route exact path="/logout" Component={Logout}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App;