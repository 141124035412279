import axios from "axios";
import React, {useEffect, useState } from "react";
import { uri } from "../util/Rest";

const EditUser = ({cancel, submit, data}) => {
    const [formState, setFormState] = useState({id: -1, username: "", userRole: ""});

    useEffect(() => {
        setFormState({
            id: data.id,
            username: data.username,
            userRole: data.userRole
        })
    }, [data])
    const handleSubmit = async (event) => {
        event.preventDefault();
        await axios.put(`${uri}/user`, formState)
        .then(() => {
            submit();
        });
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value})
    }

    return (
        <div className="col py-3">
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input type="text" className="form-control" name="username" id="username" value={formState.username} onChange={handleChange} placeholder="Enter Username" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="userRole" className="form-label">UserRole</label>
                <div className="input-group">
                <input type="text" className="form-control" name="userRole" id="userRole" value={formState.userRole} onChange={handleChange} placeholder="Enter User Role" required/>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <button type="submit" className="btn btn-outline-primary btn w-25 mb-3">Edit</button>
                <button type="submit" onClick={cancel} className="btn btn-outline-secondary btn w-25 mb-3">Cancel</button>
            </div>
            </form>
        </div>
    )
}

export default EditUser;