/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar";
import {AuthContext} from '../util/AuthContext';
import Auth from "../util/Auth";
import { uri } from "../util/Rest";
import AlertComponent from "./AlertComponent";
import axios from "axios";
const Settings = () => {
    const context = useContext(AuthContext);
    var userId = -1;
    const [user, setUser] = useState({});
    const [jobs, setJobs] = useState("");
    const [warranties, setWarranties] = useState("");
    const [formState, setFormState] = useState("");
    const [passError, setPassError] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMsg, setShowAlertMsg] = useState("");

    useEffect(() => {
        setUser(context.user)
        setJobs(context.jobs);
        setWarranties(context.warranties);
        if(user === {} || user === null) {
            userId = -1;
        }
        setFormState({currentPassword: "", newPassword: "", repeatPassword: "", user_id: userId})
    }, [context.jobs, context.user, context.warranties, user])

    const handleSubmit = async (event) => {
        event.preventDefault();
        await axios.post(`${uri}/changePassword`, formState)
        .then(() => {
            setShowAlert(true);
            setShowAlertMsg("Password changed successfully!")
            setFormState({currentPassword: "", newPassword: "", repeatPassword: "", user_id: context.user.id})
        })
        .catch((error) => {
            if(error) {
                if(error.response) {
                    setPassError(error.response.data.message);
                }
            }
        });
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        if(name === "newPassword") {
            if(value.length < 8 && value.length > 0) {
                setPassError("Password must be 8 characters or more.")
            } else {
                setPassError("")
            }
        }
        setFormState({...formState, [name]: value})
    }

    const handlePasswordValidity = () => {
        if(formState.newPassword) {
            if(formState.newPassword.length > 0) {
                if(formState.newPassword !== formState.repeatPassword) {
                    return false;
                }
                if(formState.newPassword.length < 8) {
                    return false;
                }
                return true;
    
            }
        }
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <Navbar/>
                    {user !== null ?
                    <div className="col py-3">
                    {showAlert === true ?
                            <div className="col-10 col-lg-5">
                                <AlertComponent message={showAlertMsg} dismissTime={5000}/>
                            </div>
                            : "" }
                        <div className="d-flex justify-content-center">
                            <div className="card text-center" style={{"width": "30rem"}}>
                                <div className="card-body">
                                    <h4 className="card-title">Profile</h4>
                                    <p className="lead card-text">{user.username}</p>
                                    <p className="text-muted card-text">{user.userRole > 3 ? "Administrator" : "User"}</p>
                                    <div className="row">
                                        <div className="col">
                                            <p className="fw-bold">Jobs</p>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="fw-bold text-success">{jobs.length}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="fw-bold">Warranties</p>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="fw-bold text-success">{warranties.length}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <h5 className="card-title text-start text-bold">Change Password</h5>
                                    {passError && <div className="text-danger"><i className="bi bi-exclamation-triangle"></i> {passError}</div> }
                                    <form onSubmit={handleSubmit} noValidate>
                                        <div className="mb-3">
                                            <label htmlFor="currentPassword" className="form-label">Current Password</label>
                                            <div className="input-group">
                                                <input type="password" className="form-control" name="currentPassword" id="currentPassword" value={formState.currentPassword} onChange={handleChange} placeholder="Enter Current Password" required/>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="newPassword" className="form-label">New Password</label>
                                            <div className="input-group">
                                                <input type="password" className={handlePasswordValidity() === true ? "form-control is-valid" : "form-control is-invalid"} name="newPassword" id="newPassword" value={formState.newPassword} onChange={handleChange} placeholder="Enter New Password" required/>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="repeatPassword" className="form-label">Confirm Password</label>
                                            <div className="input-group">
                                                <input type="password" className={handlePasswordValidity() === true? "form-control is-valid" : "form-control is-invalid"} name="repeatPassword" id="repeatPassword" value={formState.repeatPassword} onChange={handleChange} placeholder="Enter New Password Again" required/>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                        <button type="submit" className={handlePasswordValidity() === true ? "btn btn-outline-primary w-50 mb-3"  : "btn btn-outline-primary w-50 mb-3 disabled"}>Change Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    : "" }
                </div>
            </div>
        </div>
    )
}

export default Auth(Settings);