import React from "react";
import {Line} from "react-chartjs-2"
// eslint-disable-next-line no-unused-vars
import Chart from "chart.js/auto";

const LineChart = (props) => {
    let todaysDate = new Date().getMonth();

    if(todaysDate <= 2) {
        props.data[11].values = 0;
        props.data[10].values = 0;
    }
    var delayed;
    const data = {
        labels: props.data.map((data) => data.labels),
        datasets: [
          {
            label: props.label,
            backgroundColor: [
                'rgb(75, 192, 192)'
              ],
            borderColor: [
                'rgb(75, 192, 192)'
            ],
            tension: 0.2,
            data: props.data.map((data) => data.values)
          },
        ],
        options: {
            responsive: true,
            animation: {
                onComplete: () => {
                    delayed = true
                },
                delay: (context) => {
                    let delay = 0;
                    if (context.type === "data" && context.mode === "default" && !delayed) {
                        delay = context.dataIndex * 300 + context.dataIndex * 100
                    }
                    return delay;
                },
            },
            maintainAspectRatio: false
        }
      };
    return (
        <div>
            <Line options={data.options} height={300} width={100} data={data}/>
        </div>
    )
}


export default LineChart;